var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("j-editable-table", {
    attrs: {
      columns: _vm.columns,
      dataSource: _vm.dataSource,
      rowNumber: true,
      rowSelection: true,
      maxHeight: 400,
      disabled: true,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }